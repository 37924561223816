import React, { useEffect, useState } from "react";
import PublicKeyService from "../../../services/PublicKeyService";
import AdmissionService from "../../../services/AdmissionService";
import image from '../../../assets/images/ssh-key.png';
import { useHistory } from "react-router-dom";
import { useInput } from "../../../components/UseInput";
import { Form, Input, Button } from 'antd';
import DialogUtils from '../../../Utility/DialogUtils';

const { TextArea } = Input;

const PublicKeyView = (props: any) => {
  const [form] = Form.useForm();
  const [wrong, setWrong]: any = useState(false);
  const [savedKey, setSavedKey]: any = useState([]);
  const { value: key, bind: bindKey } = useInput(savedKey ? savedKey.userAnswer : "");
  const { setCurrent, viewMode } = props;
  const history = useHistory();

  useEffect(() => {
    const initialize = async () => {
      const publicKeyService = new PublicKeyService();
      const result = await publicKeyService.getKey();
      setSavedKey(result);

      form.setFieldsValue({
        key: result.userAnswer,
      });
    };

    initialize();
  }, [form]);

  const handleSubmit = async (value: any) => {
    let data: any = {
      publicKey: value.key ? value.key : savedKey.userAnswer,
      draft: false,
    };
    const publicKeyService = new PublicKeyService();
    const response = await publicKeyService.submitKey(data);
    if (response.success) {
      setCurrent(6);
      window.scrollTo({top: 0, behavior: 'smooth'});
    }

    if (response.status === 422) {
      setWrong(true);
    }
  };

  const saveLater = async () => {
    const data = {
      page: "public-key",
      number: 0,
    };
    const admissionService = new AdmissionService();
    const ifsave = await admissionService.saveForLater(data);

    if(ifsave){
      DialogUtils.success('Successfully Save for later.', () => {});
    }

    const draft: any = {
      publicKey: key ? key : savedKey.userAnswer,
      draft: true,
    };

    const publicKeyService = new PublicKeyService();
    await publicKeyService.submitKey(draft);
  };

  const backToSettings = async () => {
    history.push("/settings");
  };

  const prevChallenge = async () => {
    setCurrent(4);
  };

  return (
    <div className="public-key-view">
      <div className="flex-col">
        <span className="font-18">Public Key</span>
        <span className="color-gray" style={{ marginTop: 10 }}>
          In this challenge, you are tasked with generating and uploading your
          public ssh key to our system. Note that you should only upload your
          public key and never share you private key with anyone. To make it
          easier to get started, we've attached an example of what an SSH key
          looks like below. You'll need to generate your own.
        </span>
      </div>
      <hr></hr>
      <div className="flex-col items-center">
        <div className="ssh-key flex-row justify-center items-center">
          <label className="image-container">
            <img
              src={image}
              alt="SSH Key"
            />
          </label>
        </div>
        <Form 
          form={form}
          name="publicKeyForm"
          layout="vertical"
          onFinish={handleSubmit}
          style={{ width: "100%" }}
        > 
          <Form.Item
            label={
              <div>
                <span>Learn more about ssh keys </span>
                <a
                  href="https://git-scm.com/book/en/v2/Git-on-the-Server-Generating-Your-SSH-Public-Key"
                  target="_blank"
                  className="color-black font-weight-500"
                  rel='noopener noreferrer'
                >
                  here.
                </a>
              </div>
            }
            name="key"
            rules={[{ required: false, message: '' }]}
          >      
            <TextArea 
              rows={4} 
              id="key"
              disabled={wrong}
              placeholder="Enter your public ssh key here"
              // defaultValue={savedKey.userAnswer}
              readOnly={viewMode}
              style={{ resize: "none" }}
              {...bindKey}
            />     
          </Form.Item>
          {viewMode ? (
            <div
              style={{ width: "100%", marginTop: 30 }}
              className="items-center flex-row justify-center"
            >
              <Form.Item>
                <Button
                  type="default"
                  onClick={backToSettings}
                  style={{ marginRight: 10 }}
                  data-testid="back"
                >
                  Go Back
                </Button>
              </Form.Item>
              <Form.Item>
                <Button
                  type="primary"
                  onClick={prevChallenge}
                  style={{ marginRight: 10 }}
                  data-testid="prev"
                >
                  View Previous Challenge
                </Button>
              </Form.Item> 
            </div>
          ) : (
            <div
              style={{ width: "100%" }}
              className="items-center flex-row justify-center"
            >
              {!wrong ? (
                <Form.Item>
                  <Button
                    type="default"
                    onClick={saveLater}
                    style={{ marginRight: 10 }}
                    data-testid="draft"
                  >
                    Save for Later
                  </Button>
                </Form.Item>
              ) : (
                ""
              )}
              {!wrong ? (
                <Form.Item>
                  <Button
                    type="primary"
                    data-testid="submit"
                    htmlType="submit"
                  >
                    Continue
                  </Button>
                </Form.Item>
              ) : (
                ""
              )}
            </div>
          )}
        </Form>
      </div>
    </div>
  );
};

export default PublicKeyView;
