import APIService from "./APIService";

class ChaptersService extends APIService {
  getAllChapters = async () => {
    const { data } = await this.get("chapter/all") || {};
    return data;
  };
  getChapter = async (id: string) => {
    const { data } = await this.get(`school-portal/chapters/${id}`) || {};
    return data;
  }
}

export default ChaptersService;
