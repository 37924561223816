import APIService from "./APIService";

class LoginService extends APIService {
  login = async ({ username, password }: any) => {
    const result = await this.post(
      { username, password },
      "student/authenticate/login"
    );
    return result ? result.data : null;
  };

  logout = async ({ id }: any) => {
    const result = await this.post(
      { id },
      "student/authenticate/logout"
    );
    return result ? result.data : null;
  };

  isLoggedIn = () => {
    return localStorage.getItem("token") ? true : false;
  };

  isActive = () => {
    const isActive = localStorage.getItem("isActive");

    return (isActive == 'true') ? true : false;
  };

  isStudent = () => {
    const role = localStorage.getItem("role");

    return (role == 'student') ? true : false;
  };
}

export default LoginService;
