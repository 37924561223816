import React, { useEffect, useState } from "react";
import DecodingService from "../../../services/DecodingService";
import AdmissionService from "../../../services/AdmissionService";
import { useInput } from "../../../components/UseInput";
import { useHistory } from "react-router-dom";
import { Form, Input, Button } from 'antd';
import DialogUtils from '../../../Utility/DialogUtils';

const { TextArea } = Input;

const DecodingView = (props: any) => {
  const [form] = Form.useForm();
  const [wrong, setWrong]: any = useState(false);
  const [code, setCode]: any = useState([]);
  const { value: decoded, bind: bindDecoding } = useInput(code ? code.userAnswer : "");
  const { setCurrent, viewMode } = props;
  const history = useHistory();

  useEffect(() => {
    const initialize = async () => {
      const decodingService = new DecodingService();
      const result = await decodingService.getCode();
      setCode(result);

      form.setFieldsValue({
        decoded: result.userAnswer,
      });
    };

    initialize();
  }, [form]);

  const handleSubmit = async (value: any) => {
    let data: any = {
      decodedString: value.decoded ? value.decoded : code.userAnswer,
      asciiCode: code.ascii,
      draft: false,
    };
    const decodingService = new DecodingService();
    const response = await decodingService.submitCode(data);
    if (response.success) {
      setCurrent(4);
      window.scrollTo({top: 0, behavior: 'smooth'});
    }

    if (response.status === 422) {
      setWrong(true);
    }
  };

  const saveLater = async () => {
    const data = {
      page: "ascii",
      number: 0,
    };
    const admissionService = new AdmissionService();
    const ifsave = await admissionService.saveForLater(data);

    if(ifsave){
      DialogUtils.success('Successfully Save for later.', () => {});
    }

    const draft: any = {
      decodedString: decoded ? decoded : code.userAnswer,
      asciiCode: code.ascii,
      draft: true,
    };
    
    const decodingService = new DecodingService();
    await decodingService.submitCode(draft);
  };

  const backToSettings = async () => {
    history.push("/settings");
  };

  const prevChallenge = async () => {
    setCurrent(2);
  };

  const nextChallenge = async () => {
    setCurrent(4);
  };

  return (
    <div className="decoding-view">
      <div className="flex-col">
        <span className="font-18">Decoding Challenge</span>
        <span className="color-gray" style={{ marginTop: 10 }}>
          For this challenge, you will be required to decode ASCII. ASCII is the
          American Standard Code for Information Interchange used for encoding
          information. Once decoded, you will discover a message. Paste that
          message in the textbox below.
        </span>
      </div>
      <hr></hr>
      <div className="flex-col items-center">
        <div className="code flex-row justify-center items-center">
          <label className="code-container">{code ? code.ascii : ""}</label>
        </div>
        <Form 
          form={form}
          name="decodingForm"
          layout="vertical"
          onFinish={handleSubmit}
          style={{ width: "100%" }}
        > 
          <Form.Item
            label="Enter the encoded message below:"
            name="decoded"
            rules={[{ required: false, message: '' }]}
          >      
            <TextArea 
              rows={4} 
              id="decoding"
              disabled={wrong}
              placeholder="Enter code here"
              readOnly={viewMode}
              style={{ resize: "none" }}
              data-testid="decoding"
              {...bindDecoding}
            />     
          </Form.Item>
          {viewMode ? (
            <div
              style={{ width: "100%", marginTop: 30 }}
              className="items-center flex-row justify-center"
            >
              <Form.Item>
                <Button
                  type="default"
                  onClick={backToSettings}
                  style={{ marginRight: 10 }}
                  data-testid="back"
                >
                  Go Back
                </Button>
              </Form.Item>
              <Form.Item>
                <Button
                  type="primary"
                  onClick={prevChallenge}
                  style={{ marginRight: 10 }}
                  data-testid="prev"
                >
                  View Previous Challenge
                </Button>
              </Form.Item> 
              <Form.Item>
                <Button
                  type="primary"
                  onClick={nextChallenge}
                  data-testid="next"
                >
                  View Next Challenge
                </Button>
              </Form.Item>
            </div>
          ) : (
            <div
              style={{ width: "100%" }}
              className="items-center flex-row justify-center"
            >
              {!wrong ? (
                <Form.Item>
                  <Button
                    type="default"
                    onClick={saveLater}
                    style={{ marginRight: 10 }}
                    data-testid="draft"
                  >
                    Save for Later
                  </Button>
                </Form.Item>
              ) : (
                ""
              )}
              {!wrong ? (
                <Form.Item>
                  <Button
                    type="primary"
                    data-testid="submit"
                    htmlType="submit"
                  >
                    Continue
                  </Button>
                </Form.Item>
              ) : (
                ""
              )}
              {wrong ? (
                <Form.Item>
                  <Button
                    type="primary"
                    onClick={() => setCurrent(4)}
                    data-testid="next"
                  >
                    Next Challenge
                  </Button>
                </Form.Item>
              ) : (
                ""
              )}
            </div>
          )}
        </Form>
      </div>
    </div>
  );
};

export default DecodingView;
