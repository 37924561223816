import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { BrowserRouter as Router, Switch } from 'react-router-dom';
import MainView from './layout/MainView';
import lazyLoader from './hoc/LazyLoader';
import Loader from './components/Loader';
import PublicRoute from './hoc/PublicRoute';
import LoginService from './services/LoginService';
import DialogUtils from './Utility/DialogUtils';
import { isArray } from "util";
import './App.css';

const AppView: any = lazyLoader(() => import ('./pages/app/AppView'));
const LoginView: any = lazyLoader(() => import ('./pages/app/LoginView'));
const SignUpView: any = lazyLoader(() => import ('./pages/app/SignUpView'));

function App() {
  const [loader, setLoader] = useState(false);
  const authService = new LoginService();
  
  useEffect( () => { 
    axios.interceptors.request.use(function (config) {
      setLoader(true);
      return config
    }, function (error) {
      return Promise.reject(error);
    });

    axios.interceptors.response.use(function (response) {
      setLoader(false);
      return response;
    }, async (error) => {
      if (error.response) {
        showError(error.response.data.errors);
      } else {
        showError(null);
      }

      setLoader(false);
      return Promise.reject(error);
    });
  }, []); 

  const showError = (errors:any) => {
    let content = null;
    if (isArray(errors)) {
      content = (
        <div>
          {errors.map((item:any, index:number) => { return <div key={Math.random().toString(6)}>{item.message}</div>})}
        </div>    
      );
    } else {
      content = ( 
        <div style={{whiteSpace: 'pre-line'}}>
          {errors ? errors : 'There was an error with your network. Please try again later.'}
        </div>
      );
    }
    
    DialogUtils.error(content);
  }
  
  return (
    <div>
      <Router>
        <Switch>
          <PublicRoute
            auth={{ isLoggedIn: () => authService.isLoggedIn() }}
            exact
            path="/admission"
            component={AppView}
          />
          <PublicRoute
            auth={{ isLoggedIn: () => authService.isLoggedIn() }}
            exact
            path="/sign-up"
            component={SignUpView}
          />
          <PublicRoute
            auth={{ isLoggedIn: () => authService.isLoggedIn() }}
            exact
            path="/login"
            component={LoginView}
          />
          <MainView></MainView>
        </Switch>
      </Router>
      <Loader show={loader} />
    </div>
  );  
}

export default App;