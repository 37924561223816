import APIService from "./APIService";

class ComprehensionService extends APIService {
  getComprehension = async () => {
    const result = await this.get("student/apply/comprehension");
    return result ? result.data : null;
  };

  submitComprehension = async (data: any) => {
    const result = await this.post(data, "student/apply/submit/comprehension");
    return result ? result.data : null;
  };

  submitComprehensionFinal = async (data: any) => {
    const result = await this.put(data, "student/apply/validate/comprehension");
    return result ? result.data : null;
  };
}

export default ComprehensionService;
