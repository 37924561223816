import APIService from "./APIService";

class PublicKeyService extends APIService {
  getKey = async () => {
    const result = await this.get("student/apply/validate/publickey");
    return result ? result.data : null;
  };

  submitKey = async (data: any) => {
    const result = await this.post(data, "student/apply/validate/publickey");
    return result ? result.data : null;
  };
}

export default PublicKeyService;
