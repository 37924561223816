import React, { useState } from "react";

const ComprehensionOptions = ({ options, selectedOpt }: any) => {
  const [selected, setSelected] = useState("");

  const handleClick = async (value: any) => {
    setSelected(value);
    selectedOpt(value);
  };

  return (
    <div className="wrapper">
      {options.map((opt: any, index: number) => {
        return (
          <div
            key={index}
            className={
              selected === opt.optionId
                ? "option-container active"
                : "option-container"
            }
            onClick={() => handleClick(opt.optionId)}
          >
            {opt.option}
          </div>
        );
      })}
    </div>
  );
};

export default ComprehensionOptions;
