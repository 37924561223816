/**
 * App configuration
 */
class APPConfig {
  /**
   * api url endpoint
   */
  static API_URL: string = process.env.REACT_APP_API_ENDPOINT ? process.env.REACT_APP_API_ENDPOINT : '';
  static CURRICULUM_URL: string = process.env.CURRICULUM_URL ? process.env.CURRICULUM_URL : 'http://curriculum-arkademy.ark.ph/';
  static SCHOOL_NAME: string = 'Harbinger School';
  static STRIPE_PK: string = process.env.STRIPE_PK ? process.env.STRIPE_PK : 'pk_test_51Hk1ktJxuztrCFAKMhHngiN7rNGgdqEOItUyDmxMeMRjU8M3ulD6s1OCye8XZSqxo1kSKQfgIpFs7sbZEwrvMyKs00IVJNLfQZ';
  static SLACK_URL: string = process.env.SLACK_URL ? process.env.SLACK_URL : 'https://app.slack.com/client/TD6MEN37A/CD6RCV281';
  static MOODLE_URL: string = process.env.MOODLE_URL ? process.env.MOODLE_URL : 'http://portal.harbingerschool.com/';
}

export default APPConfig;
