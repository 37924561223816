import React from "react";
import { Result } from 'antd';

const ApplicationCompleted = () => {
  return (
    <Result
      status="success"
      title="Application Complete"
      subTitle="You have completed all the parts of the admission process. We will be in touch to schedule your interview soon."
      style={{ padding: "100px 32px" }}
    />
  );
};

export default ApplicationCompleted;
