import React, { useEffect, useState } from "react";
import { Layout, Tooltip } from "antd";
import { ExportOutlined } from '@ant-design/icons';
import moment from "moment";
import AdmissionService from "../services/AdmissionService";
import Sidebar from "../pages/admission-portal/sidebar/Sidebar";
import PersonalityView from "../pages/admission-portal/personality/PersonalityView";
import ComprehensionView from "../pages/admission-portal/comprehension/ComprehensionView";
import DecodingView from "../pages/admission-portal/decoding/DecodingView";
import ScavengerHuntView from "../pages/admission-portal/scavenger-hunt/ScavengerHuntView";
import PublicKeyView from "../pages/admission-portal/public-key/PublicKeyView";
import ChallengeCompleted from "../pages/admission-portal/results/ChallengeCompleted";
import ApplicationCompleted from "../pages/admission-portal/results/ApplicationComplete";
import ApplicationExpired from "../pages/admission-portal/results/ApplicationExpired";
import harbingerImage from "../assets/images/harbinger.png";

const { Sider, Content, Header } = Layout;

const ApplicantLayoutView = (props: any) => {
  const [current, setCurrent] = useState(0);
  const [daysLeft, setDaysLeft] = useState(0);
  const [currentQ, setCurrentQ] = useState("");
  const [expired, setExpiry] = useState(false); 
  const [complete, setComplete] = useState(false);

  useEffect(() => {
    const initialize = async () => {
      const admissionService = new AdmissionService();
      const result = await admissionService.getSession();

      switch (result.previousSession.page) {
        case "personality":
          setCurrent(1);
          break;
        case "comprehension":
          setCurrent(2);
          break;
        case "ascii":
          setCurrent(3);
          break;
        case "scavenger-hunt":
          setCurrent(4);
          break;
        case "public-key":
          setCurrent(5);
          break;
        default:
          setCurrent(1);
          break;
      }

      setCurrentQ(result.previousSession.number);
      setComplete(result.applicationComplete);
      const endDate = new Date(result.startingDate);
      endDate.setDate(endDate.getDate() + 8);
      const currDate = new Date();
      const end = moment(endDate);
      const cur = moment(currDate);
      setDaysLeft(end.diff(cur, "days"));

      if (end.diff(cur, "days") <= 0) {
        setExpiry(true);
      }
    };

    initialize();
  }, []);

  const onLogout = () => {
    localStorage.clear();
    window.location.reload(false);
  };

  return (
    <Layout>
        <Sider 
          theme="light" 
          trigger={null} 
          collapsible 
          style={{ background: "rgb(244, 245, 247)" }}
          width={220}
        >
          <Sidebar current={current} days={daysLeft} complete={complete} expired={expired} viewMode={false} />
        </Sider>
        <Layout>
            {/* <Header className="header flex-row items-center justify-end">
              <div className="flex-row justify-between items-center" style={{ width: "100%" }}>
                <a href={"/"} className="flex-row items-center">
                  <div
                    className="flex-row items-center justify-center"
                  >
                    <img src={harbingerImage} width="120" alt="profile-img" />        
                  </div>
                </a>
                
              </div>
                <div className="font-20 flex-row items-center admission-logout">
                  <Tooltip placement="bottomRight" title={'Logout'}>
                    <ExportOutlined onClick={onLogout}/>
                  </Tooltip>
                </div>
            </Header> */}
            <Content style={{ background: "#FFF", height: '100vh', overflow: 'auto' }}>
              {!expired ? (
                
                <div className="home-view">
                  {complete ? (
                    <span>
                      <ApplicationCompleted />
                    </span>
                  ) : (
                    <span>
                      {current === 1 ? <PersonalityView setCurrent={setCurrent} viewMode={false} /> : ""}
                      {current === 2 ? (
                        <ComprehensionView setCurrent={setCurrent} currentQ={currentQ} />
                      ) : (
                        ""
                      )}
                      {current === 3 ? <DecodingView setCurrent={setCurrent} viewMode={false} /> : ""}
                      {current === 4 ? <ScavengerHuntView setCurrent={setCurrent} viewMode={false} /> : ""}
                      {current === 5 ? <PublicKeyView setCurrent={setCurrent} viewMode={false} /> : ""}
                      {current === 6 ? <ChallengeCompleted setCurrent={setCurrent} viewMode={false} /> : ""}
                    </span>
                  )}
                  <div className={!complete && current !== 6 ? "warning-notice color-red" : "warning-notice color-red hidden"}>
                    {daysLeft > 0
                      ? `You have ${daysLeft} days left to submit this application`
                      : ""}
                  </div>
                </div>
              ) : (
                <div className="home-view">
                  <ApplicationExpired />
                </div>
              )}
            </Content>
        </Layout>
      </Layout>
  );
}

export default ApplicantLayoutView;
