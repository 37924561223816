import React from "react";

const ProgressRing = (props: any) => {
  const { radius, stroke, progress, label } = props;
  const normalizedRadius = radius - stroke * 2;
  const circumference = normalizedRadius * 2 * Math.PI;
  const strokeDashoffset = circumference - progress / 100 * circumference;

  return (
    <svg
      height={radius * 2}
      width={radius * 2}
      >
      <circle
        stroke="#007bff"
        fill="transparent"
        strokeWidth={ stroke }
        strokeDasharray={ circumference + ' ' + circumference }
        style={ { strokeDashoffset } }
        r={ normalizedRadius }
        cx={ radius }
        cy={ radius }
        />
      <g className="circle-label">
          <text x="50%" y="50%" className="circle-progress">
            { label }
          </text>
        </g>
    </svg>
  )
};

export default ProgressRing;
