import React, { useEffect, useState } from "react";
import ScavengerHuntService from "../../../services/ScavengerHuntService";
import AdmissionService from "../../../services/AdmissionService";
import { useHistory } from "react-router-dom";
import { useInput } from "../../../components/UseInput";
import { Form, Input, Button } from 'antd';
import DialogUtils from '../../../Utility/DialogUtils';

const { TextArea } = Input;

const ScavengerHuntView = (props: any) => {
  const [form] = Form.useForm();
  const [wrong, setWrong]: any = useState(false);
  const [hash, setHash]: any = useState([]);
  const { value: decrypted, bind: bindDecrypting } = useInput(hash ? hash.userAnswer : "");
  const { setCurrent, viewMode } = props;
  const history = useHistory();

  useEffect(() => {
    const initialize = async () => {
      const scavengerHuntService = new ScavengerHuntService();
      const result = await scavengerHuntService.getHash();
      setHash(result);

      form.setFieldsValue({
        decrypted: result.userAnswer,
      });
    };

    initialize();
  }, [form]);

  const handleSubmit = async (value: any) => {
    let data: any = {
      hash: hash.encrypted,
      answer: value.decrypted ? value.decrypted : hash.userAnswer,
      draft: false,
    };
    const scavengerHuntService = new ScavengerHuntService();
    const response = await scavengerHuntService.submitAnswer(data);
    if (response.success) {
      setCurrent(5);
      window.scrollTo({top: 0, behavior: 'smooth'});
    }

    if (response.status === 422) {
      setWrong(true);
    }
  };

  const saveLater = async () => {
    const data = {
      page: "scavenger-hunt",
      number: 0,
    };
    const admissionService = new AdmissionService();
    const ifsave =  await admissionService.saveForLater(data);

    if(ifsave){
      DialogUtils.success('Successfully Save for later.', () => {});
    }

    const draft: any = {
      hash: hash.encrypted,
      answer: decrypted ? decrypted : hash.userAnswer,
      draft: true,
    };
    const scavengerHuntService = new ScavengerHuntService();
    await scavengerHuntService.submitAnswer(draft);
  };

  const backToSettings = async () => {
    history.push("/settings");
  };

  const prevChallenge = async () => {
    setCurrent(3);
  };

  const nextChallenge = async () => {
    setCurrent(5);
  };

  return (
    <div className="scavenger-hunt-view">
      <div className="flex-col">
        <span className="font-18">Scavenger Hunt</span>
        <span className="color-gray" style={{ marginTop: 10 }}>
          In this section, you will be assessed on your resourcefulness and
          ability to find things. We have setup a unique coding scavenger hunt
          puzzle for you with several clues, with each step leading to another
          clue. This section will introduce you to various technical puzzles. It
          is designed so that students will be taken down a fun path of web
          actions to return a unique set of hash data needed for submission of a
          complete application. By following the clues, you will be taken to one
          final clue which will give you a code to input in the form below.
        </span>
      </div>
      <hr></hr>
      <div className="flex-col items-center">
        <div className="hash flex-row justify-center items-center">
          <label className="code-container word-break">
            {hash ? hash.encrypted : ""}
          </label>
        </div>
        <Form 
          form={form}
          name="scavengerHuntForm"
          layout="vertical"
          onFinish={handleSubmit}
          style={{ width: "100%" }}
        > 
          <Form.Item
            label="Not sure where to start? First, do some research on Base64 and figure out how to decode the text above. Once decoded, it will reveal a secret message with instructions on your next task. Good luck. When you've reached the end of the scavenger hunt, paste the decrypted message below."
            name="decrypted"
            rules={[{ required: false, message: '' }]}
          >      
            <TextArea 
              rows={4} 
              id="decrypting"
              disabled={wrong}
              placeholder="Enter the final encrypted message here"
              // defaultValue={hash.userAnswer}
              readOnly={viewMode}
              style={{ resize: "none" }}
              {...bindDecrypting}
            />     
          </Form.Item>
          {viewMode ? (
            <div
              style={{ width: "100%", marginTop: 30 }}
              className="items-center flex-row justify-center"
            >
              <Form.Item>
                <Button
                  type="default"
                  onClick={backToSettings}
                  style={{ marginRight: 10 }}
                  data-testid="back"
                >
                  Go Back
                </Button>
              </Form.Item>
              <Form.Item>
                <Button
                  type="primary"
                  onClick={prevChallenge}
                  style={{ marginRight: 10 }}
                  data-testid="prev"
                >
                  View Previous Challenge
                </Button>
              </Form.Item> 
              <Form.Item>
                <Button
                  type="primary"
                  onClick={nextChallenge}
                  data-testid="next"
                >
                  View Next Challenge
                </Button>
              </Form.Item>
            </div>
          ) : (
            <div
              style={{ width: "100%" }}
              className="items-center flex-row justify-center"
            >
              {!wrong ? (
                <Form.Item>
                  <Button
                    type="default"
                    onClick={saveLater}
                    style={{ marginRight: 10 }}
                    data-testid="draft"
                  >
                    Save for Later
                  </Button>
                </Form.Item>
              ) : (
                ""
              )}
              {!wrong ? (
                <Form.Item>
                  <Button
                    type="primary"
                    data-testid="submit"
                    htmlType="submit"
                  >
                    Continue
                  </Button>
                </Form.Item>
              ) : (
                ""
              )}
              {wrong ? (
                <Form.Item>
                  <Button
                    type="primary"
                    onClick={() => setCurrent(5)}
                    data-testid="next"
                  >
                    Next Challenge
                  </Button>
                </Form.Item>
              ) : (
                ""
              )}
            </div>
          )}
        </Form>
      </div>
    </div>
  );
};

export default ScavengerHuntView;
