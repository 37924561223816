import React, { useEffect, useState, Fragment } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useHistory } from "react-router-dom";
import {
  faFeather,
  faHammer,
  faUsers,
  faUniversity,
  faCog,
  faDownload,
  faFolderOpen,
  faSpa, faUserFriends,
  faComment, faGraduationCap,
  faFileAlt,
  faHashtag,
  faTasks
} from "@fortawesome/free-solid-svg-icons";
import harbingerImage from "../../../assets/images/harbinger.png";
import { Menu, Tooltip, Row, Col, Space, Typography, Tag } from "antd";
import { ExportOutlined } from '@ant-design/icons';
import LoginService from "../../../services/LoginService";
import APPConfig from "../../../services/config";
import AdmissionService from "../../../services/AdmissionService";
import { titleCase } from "../../../Utility/TextTransformUtils";
import SubMenu from "antd/lib/menu/SubMenu";
import ChaptersService from "../../../services/ChapterService";


const Sidebar = (props: any) => {
  const history = useHistory();
  const path = window.location.pathname;
  const [currentPath, setCurrentPath] = useState(window.location.pathname);
  const [isActive, setIsActive] = useState(false);
  const slack = useState(APPConfig.SLACK_URL);
  const moodle = useState(APPConfig.MOODLE_URL);
  const [chapterChunks, setChapterChunks] = useState<string[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  const [student, setStudent]: any = useState();
  const [studentImage, setStudentImage]: any = useState(null);

  useEffect(() => {
    setCurrentPath(path);
    const authService = new LoginService();
    const active = authService.isActive();

    if (active)
      setIsActive(true);
    else
      setIsActive(false);

  }, [path]);

  useEffect(() => {
    const initialize = async () => {
      setLoading(true);
      const admissionService = new AdmissionService();
      const chapterService = new ChaptersService();
      const response = await admissionService.getSession();
      const chapter = await chapterService.getChapter(response.currentChapter.chapter);

      setChapterChunks([chapter.name.slice(0, 40), chapter.name.slice(40)]);
      setStudent(response);
      setStudentImage(response.image)
      setLoading(false);
    };
    initialize();
  }, []);

  const onLogout = () => {
    const authService = new LoginService();
    const userId = localStorage.getItem("userId");
    authService.logout({ id: userId });
    localStorage.clear();
    window.location.reload(false);
  };

  return (
    <div className="page-sidebar student-sidebar">
      <div
        className="flex-row items-center justify-center"
        style={{ paddingBottom: 20 }}
      >
        <img src={harbingerImage} width="120" alt="profile-img" />
      </div>
      <div style={{ marginLeft: 10, marginRight: 10 }}>
        <Tag color="red" style={{ width: '100%' }}>
          <Typography.Text style={{ fontSize: '12px', fontWeight: 'bold' }}>{loading ? 'Getting Current Chapter...' : chapterChunks.map(item => {
            return (
              <Fragment key={item}>
                {item} <br />
              </Fragment>
            )
          })}</Typography.Text>

        </Tag>
      </div>
      {isActive ?
        (
          <Menu defaultSelectedKeys={[path]} mode="inline" style={{ background: "transparent", overflowY: 'auto', height: 'calc(100% - 106px)' }}>
            <Menu.ItemGroup key="g1" title={'Quick View'}>
              <Menu.Item
                style={{ visibility: isActive ? 'visible' : 'hidden' }}
                key="/"
                icon={<FontAwesomeIcon icon={faSpa} style={{ width: 22, fontSize: 16, marginRight: 10 }} />}
                onClick={() => history.push("/")}>
                <span className="font-14">Dashboard</span>
              </Menu.Item>
              <Menu.Item
                style={{ visibility: isActive ? 'visible' : 'hidden' }}
                key="5"
                // onClick={() => history.push("/forums")}
                icon={<FontAwesomeIcon icon={faUsers} style={{ width: 22, fontSize: 16, marginRight: 10 }} />}
              >
                <a
                  href="https://forums.harbingerschool.com/login" //https://forums.bfloschool.com/
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <span className="font-14">Forums</span>
                </a>

              </Menu.Item>
              <Menu.Item
                style={{ visibility: isActive ? 'visible' : 'hidden' }}
                key="/office-hours"
                icon={<FontAwesomeIcon icon={faFileAlt} style={{ width: 22, fontSize: 16, marginRight: 10 }} />}
                onClick={() => history.push("/office-hours")}
              >
                <span className="font-14">Office Hours</span>
              </Menu.Item>
              <Menu.Item
                style={{ visibility: isActive ? 'visible' : 'hidden' }}
                key="10"
                icon={<FontAwesomeIcon icon={faHashtag} style={{ width: 22, fontSize: 16, marginRight: 10 }} />}
              >
                <span className="font-14">
                  <a target="_blank" rel="noopener noreferrer" href={slack[0]}>Slack</a>
                </span>
              </Menu.Item>
            </Menu.ItemGroup>

            <Menu.ItemGroup key="g2" title={'Resources'}>
              <Menu.Item
                style={{ visibility: isActive ? 'visible' : 'hidden' }}
                key="/chapters"
                icon={<FontAwesomeIcon icon={faTasks} style={{ width: 22, fontSize: 16, marginRight: 10 }} />}
                onClick={() => history.push("/chapters")}
              >
                <span className="font-14">Chapters</span>
              </Menu.Item>
              <Menu.Item
                style={{ visibility: isActive ? 'visible' : 'hidden' }}
                key="/directory"
                icon={<FontAwesomeIcon icon={faFolderOpen} style={{ width: 22, fontSize: 16, marginRight: 10 }} />}
                onClick={() => history.push("/directory")}
              >
                <span className="font-14">Directory</span>
              </Menu.Item>
              <Menu.Item
                style={{ visibility: isActive ? 'visible' : 'hidden' }}
                key="/payment"
                icon={<FontAwesomeIcon icon={faUniversity} style={{ width: 22, fontSize: 16, marginRight: 10 }} />}
                onClick={() => history.push("/payment")}
              >
                <span className="font-14">Payment</span>
              </Menu.Item>
              <Menu.Item
                style={{ visibility: isActive ? 'visible' : 'hidden' }}
                key="/downloads"
                icon={<FontAwesomeIcon icon={faDownload} style={{ width: 22, fontSize: 16, marginRight: 10 }} />}
                onClick={() => history.push("/downloads")}
              >
                <span className="font-14">Downloads</span>
              </Menu.Item>
              <Menu.Item
                style={{ visibility: isActive ? 'visible' : 'hidden' }}
                key="3"
                icon={<FontAwesomeIcon icon={faHammer} style={{ width: 22, fontSize: 16, marginRight: 10 }} />}
                onClick={() => history.push("/projects")}
              >
                <span className="font-14">Projects</span>
              </Menu.Item>
              {/* <SubMenu key="projectsubs" icon={<FontAwesomeIcon icon={faHammer} style={{ width: 22, fontSize: 16, marginRight: 10, visibility: isActive ? 'visible' : 'hidden' }} />} title="Projects">
                <Menu.Item key="/courses" onClick={() => history.push("/courses")}>Courses List</Menu.Item>
                <Menu.Item key="/lessons" onClick={() => history.push("/lessons")}>Lessons List</Menu.Item>
                <Menu.Item key="/projects" onClick={() => history.push("/projects")}>Project List</Menu.Item>
              </SubMenu> */}
            </Menu.ItemGroup>

            <Menu.ItemGroup key="g3" title={'Course'}>
              <Menu.Item
                style={{ visibility: isActive ? 'visible' : 'hidden' }}
                key="/quizzes"
                icon={<FontAwesomeIcon icon={faFeather} style={{ width: 22, fontSize: 16, marginRight: 10 }} />}
                onClick={() => history.push("/quizzes")}
              >
                <span className="font-14">Quizzes</span>
              </Menu.Item>
              <Menu.Item
                style={{ visibility: isActive ? 'visible' : 'hidden' }}
                key="11"
                icon={<FontAwesomeIcon icon={faGraduationCap} style={{ width: 22, fontSize: 16, marginRight: 10 }} />}
                onClick={() => history.push("/gradebook")}
              >
                <span className="font-14">
                  {/* <a target="_blank" rel="noopener noreferrer" href={moodle[0]}>Gradebook</a> */}
                  Gradebook
                </span>
              </Menu.Item>
              <Menu.Item
                style={{ visibility: isActive ? 'visible' : 'hidden' }}
                key="/interview-prep"
                icon={<FontAwesomeIcon icon={faUserFriends} style={{ width: 22, fontSize: 16, marginRight: 10 }} />}
                onClick={() => history.push("/interview-prep")}
              >
                <span className="font-14">Interview Prep</span>
              </Menu.Item>
            </Menu.ItemGroup>

            <Menu.ItemGroup key="g4" title={'Account'}>
              <Menu.Item
                style={{ visibility: isActive ? 'visible' : 'hidden' }}
                key="/settings"
                icon={<FontAwesomeIcon icon={faCog} style={{ width: 22, fontSize: 16, marginRight: 10 }} />}
                onClick={() => history.push("/settings")}
              >
                <span className="font-14">Settings</span>
              </Menu.Item>
            </Menu.ItemGroup>
          </Menu>
        )
        : null}
      <div className="logout">
        <Row justify="space-between" className="width-max" style={{ flexWrap: 'nowrap' }}>
          <Col>
            <Space>
              <div className="profile-icon flex-col items-center justify-center">
                {studentImage ? <img style={{ borderRadius: 50 }} src={studentImage} alt="profile-img" width="30" height="30" /> : student ? student.firstName && student.lastName ? `${student.firstName.substring(0, 1)}${student.lastName.substring(0, 1)}` : student.firstName.substring(0, 1) : 'Student'}
              </div>
              <div className="flex-col">
                <span style={{ lineHeight: '14px' }}>
                  {student ? student.firstName && student.lastName ? `${student.firstName} ${student.lastName}` : student.firstName : 'Student'}
                </span>
                <span className="color-gray font-10">{student ? titleCase(student.role) : 'Student'}</span>
              </div>
            </Space>
          </Col>
          <Col >
            <Tooltip placement="bottomRight" title={'Logout'}>
              <ExportOutlined onClick={onLogout} style={{ fontSize: 16 }} className="logout-icon" />
            </Tooltip>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default Sidebar;
