import React from "react";
import { Result, Button } from 'antd';

const ChallengeCompleted = (props: any) => {
  return (
    <Result
      title="Application Sent"
      subTitle="You have successfully completed all the parts of the admission process. We will be in touch to schedule your interview soon."
      style={{ padding: "100px 32px" }}
      extra={
        <Button type="primary" key="console">
          Next
        </Button>
      }
    />
  );
};

export default ChallengeCompleted;
