import React, { useEffect, useState } from "react";
import AdmissionService from "../services/AdmissionService";
import LoginService from "../services/LoginService";
import ApplicantLayoutView from "./ApplicantLayoutView";
import ProtectedRoute from "../hoc/ProtectedRoute";
import { Route, Redirect, Switch } from "react-router-dom";
import StudentLayoutView from "./StudentLayoutView";
import lazyLoader from "../hoc/LazyLoader";

// HOME
const homeView = lazyLoader(() => import('./ApplicantLayoutView'));

// STUDENT PORTAL
const dashboard = lazyLoader(() => import('../pages/student-portal/dashboard/Dashboard'));
const dashboardLessons = lazyLoader(() => import('../pages/student-portal/dashboard/DashboardLessons'));

const projects = lazyLoader(() => import('../pages/student-portal/projects/Projects'));
const projectView = lazyLoader(() => import('../pages/student-portal/projects/ViewProject'));
const downloadsView = lazyLoader(() => import('../pages/student-portal/downloads/Downloads'));
const downloadChapter = lazyLoader(() => import('../pages/student-portal/downloads/DownloadChapter'));
const quizzesView = lazyLoader(() => import('../pages/student-portal/quizzes/Quizzes'));
const paymentView = lazyLoader(() => import('../pages/student-portal/payment/Payment'));
const stripePaymentView = lazyLoader(() => import('../pages/student-portal/payment/StripePayment'));
const updatePaymentView = lazyLoader(() => import('../pages/student-portal/payment/UpdatePayment'));
const editPaymentView = lazyLoader(() => import('../pages/student-portal/payment/EditPayment'));
const directoryView = lazyLoader(() => import('../pages/student-portal/directory/Directory'));
const studentProfileView = lazyLoader(() => import('../pages/student-portal/directory/StudentProfile'));
const studentListView = lazyLoader(() => import('../pages/student-portal/directory/StudentList'));
const settingsView = lazyLoader(() => import('../pages/student-portal/settings/Settings'));
const resourcesView = lazyLoader(() => import('../pages/student-portal/dashboard/Resources'));
const applicantView = lazyLoader(() => import('../pages/student-portal/settings/ViewApplication'));
const profileView = lazyLoader(() => import('../pages/student-portal/settings/ViewProfile'));
const interviewPrepView = lazyLoader(() => import('../pages/student-portal/interview-prep/InterviewPrep'));
const studentAppointment = lazyLoader(() => import('../pages/student-portal/appointment/Appointments'));
const addAppointment = lazyLoader(() => import('../pages/student-portal/appointment/AddAppointments'));
const editAppointment = lazyLoader(() => import('../pages/student-portal/appointment/EditAppointments'));
const chapters = lazyLoader(() => import('../pages/student-portal/chapters/Chapter'));
// PROJECTS 
const courseList = lazyLoader(() => import('../pages/student-portal/projects/CoursesView/CoursesListView'));
const chaptersList = lazyLoader(() => import('../pages/student-portal/projects/CoursesView/ChaptersView'));
const lessonsList = lazyLoader(() => import('../pages/student-portal/projects/CoursesView/LessonsView'));
const projectsList = lazyLoader(() => import('../pages/student-portal/projects/CoursesView/ProjectListView'));
// GRADEBOOK 
const gradebook = lazyLoader(() => import('../pages/student-portal/gradebook/GradebookVIew'));
const gradebookChapter = lazyLoader(() => import('../pages/student-portal/gradebook/GradebookChapterView'));
const gradebookStudents = lazyLoader(() => import('../pages/student-portal/gradebook/StudentsView'));
const gradebookProjects = lazyLoader(() => import('../pages/student-portal/gradebook/StudentProjectView'));
const gradebookLessons = lazyLoader(() => import('../pages/student-portal/gradebook/StudentLessonView'));
// FORUMS 
const forums = lazyLoader(() => import('../pages/student-portal/forums/ForumsView'));

const MainView = () => {
  const [applicant, setApplicant] = useState(false);
  const [student, setStudent] = useState(false);
  const [isActive, setIsActive] = useState(false);
  const authService = new LoginService();

  useEffect(() => {
    const initialize = async () => {
      if (localStorage.getItem('user')) {
        const admissionService = new AdmissionService();
        const result = await admissionService.getSession();
        const isActiveUser = authService.isActive();

        if (isActiveUser)
          setIsActive(true);
        else
          setIsActive(false);

        switch (result.role) {
          case 'applicant':
            setApplicant(true);
            setStudent(false);
            break;
          case 'student':
            setApplicant(false);
            setStudent(true);
            break;
        }
      }
    };

    initialize();
  }, []);

  return (
    <div>
      { applicant
        ? <ApplicantLayoutView>
          <ProtectedRoute exact path="/" component={homeView} />
          <Route
            render={(props) => {
              const { key } = props.location;
              const isLoggedIn = authService.isLoggedIn();
              if (isLoggedIn && !key) {
                return <Redirect from="*" to="/" />;
              } else if (!isLoggedIn) {
                return <Redirect from="*" to="/admission" />;
              }
            }}
          />
          {/* END */}
        </ApplicantLayoutView>
        : null
      }
      <Switch>
        {student && isActive
          ? <StudentLayoutView>
            <Switch>
              <ProtectedRoute exact path="/" component={dashboard} />
              <ProtectedRoute exact path="/chapters/" component={chapters} />
              <ProtectedRoute exact path="/dashboard/:chapterid" component={dashboardLessons} />
              <ProtectedRoute exact path='/projects' component={projects} />
              <ProtectedRoute exact path='/projects/:id' component={projectView} />
              <ProtectedRoute exact path='/downloads' component={downloadsView} />
              <ProtectedRoute exact path='/downloads/:title/:id' component={downloadChapter} />
              <ProtectedRoute exact path='/quizzes' component={quizzesView} />
              <ProtectedRoute exact path='/payment' component={paymentView} />
              <ProtectedRoute exact path='/update-payment' component={updatePaymentView} />
              <ProtectedRoute exact path='/edit-payment-details' component={editPaymentView} />
              <ProtectedRoute exact path='/directory' component={directoryView} />
              <ProtectedRoute exact path='/directory/:cohort' component={studentListView} />
              <ProtectedRoute exact path='/directory/:cohort/:id' component={studentProfileView} />
              <ProtectedRoute exact path='/settings' component={settingsView} />
              <ProtectedRoute exact path='/resources' component={resourcesView} />
              <ProtectedRoute exact path='/application' component={applicantView} />
              <ProtectedRoute exact path='/student-profile' component={profileView} />
              <ProtectedRoute exact path='/interview-prep' component={interviewPrepView} />
              <ProtectedRoute exact path='/office-hours' component={studentAppointment} />
              <ProtectedRoute exact path='/office-hours/add-office-hours' component={addAppointment} />
              <ProtectedRoute exact path='/office-hours/edit-office-hours/:id' component={editAppointment} />
              {/* PROJECTS  */}
              <ProtectedRoute exact path='/courses' component={courseList} />
              <ProtectedRoute exact path='/courses/:courseToken/chapters' component={chaptersList} />
              <ProtectedRoute exact path='/courses/:courseToken/chapter/:chapterToken/lessons' component={lessonsList} />
              <ProtectedRoute exact path='/courses/:courseToken/chapter/:chapterToken/lesson/:lessonToken/projects' component={projectsList} />
              {/* GRADEBOOK */}
              <ProtectedRoute exact path='/gradebook' component={gradebook} />
              <ProtectedRoute exact path='/gradebook/:chapterId/grades' component={gradebookChapter} />
              <ProtectedRoute exact path='/gradebook/:gradebookToken/students' component={gradebookStudents} />
              <ProtectedRoute exact path='/gradebook/:gradebookToken/student/:studentToken/projects' component={gradebookProjects} />
              <ProtectedRoute exact path='/gradebook/:gradebookToken/student/:studentToken/project/:projectToken/lesson' component={gradebookLessons} />
              {/* FORUMS  */}
              <ProtectedRoute exact path='/forums' component={forums} />

              {/* REDIRECT TO HOME IF NO PAGE FOUND */}
              <Route
                render={(props) => {
                  const { key } = props.location;
                  const isLoggedIn = authService.isLoggedIn();

                  if (isLoggedIn && !key) {
                    return <Redirect from="*" to="/" />;
                  } else if (!isLoggedIn) {
                    return <Redirect from="*" to="/admission" />;
                  }
                }}
              />
              {/* END */}
            </Switch>
          </StudentLayoutView>
          : student && !isActive
            ? <StudentLayoutView>
              <Switch>
                <ProtectedRoute exact path="/" component={dashboard} />
                <ProtectedRoute exact path='/payment-page' component={stripePaymentView} />
                <ProtectedRoute exact path='/projects' component={projects} />
                <ProtectedRoute exact path='/projects/:id' component={projectView} />
                <ProtectedRoute exact path='/downloads' component={downloadsView} />
                <ProtectedRoute exact path='/downloads/:title/:id' component={downloadChapter} />
                <ProtectedRoute exact path='/quizzes' component={quizzesView} />
                <ProtectedRoute exact path='/payment' component={paymentView} />
                <ProtectedRoute exact path='/update-payment' component={updatePaymentView} />
                <ProtectedRoute exact path='/directory' component={directoryView} />
                <ProtectedRoute exact path='/directory/:cohort' component={studentListView} />
                <ProtectedRoute exact path='/directory/:cohort/:id' component={studentProfileView} />
                <ProtectedRoute exact path='/settings' component={settingsView} />
                <ProtectedRoute exact path='/resources' component={resourcesView} />
                <ProtectedRoute exact path='/application' component={applicantView} />
                <ProtectedRoute exact path='/student-profile' component={profileView} />
                <ProtectedRoute exact path='/interview-prep' component={interviewPrepView} />
                <ProtectedRoute exact path='/office-hours' component={studentAppointment} />
                <ProtectedRoute exact path='/office-hours/add-office-hours' component={addAppointment} />
                <ProtectedRoute exact path='/office-hours/edit-office-hours/:id' component={editAppointment} />
                {/* FORUMS  */}
                <ProtectedRoute exact path='/forums' component={forums} />

                {/* REDIRECT TO HOME IF NO PAGE FOUND */}
                <Route
                  render={(props) => {
                    const { key } = props.location;
                    const isLoggedIn = authService.isLoggedIn();
                    const isActiveUser = authService.isActive();
                    const isStudent = authService.isStudent();

                    if (isLoggedIn && !isActiveUser && isStudent) {
                      return <Redirect from="*" to="/payment-page" />;
                    } else if (isLoggedIn && !key) {
                      return <Redirect from="*" to="/" />;
                    } else if (!isLoggedIn) {
                      return <Redirect from="*" to="/admission" />;
                    }
                  }}
                />
                {/* END */}
              </Switch>
            </StudentLayoutView>
            : null
        }
        <Route
          render={(props) => {
            const { key } = props.location;
            const isLoggedIn = authService.isLoggedIn();
            const isActiveUser = authService.isActive();
            const isStudent = authService.isStudent();

            if (isLoggedIn && !isActiveUser && isStudent) {
              return <Redirect from="*" to="/payment-page" />;
            } else if (isLoggedIn && !key) {
              return <Redirect from="*" to="/" />;
            } else if (!isLoggedIn) {
              return <Redirect from="*" to="/admission" />;
            }
          }}
        />
      </Switch>
    </div>
  );
};

export default MainView;
