import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faCircle } from "@fortawesome/free-solid-svg-icons";
import ProgressRing from "../../../components/ProgressRing";
import harbingerImage from "../../../assets/images/harbinger.png";
import { Row, Col, Tooltip } from "antd";
import { ExportOutlined } from "@ant-design/icons";

const Sidebar = (props: any) => {
  const { current, days, complete, expired, viewMode } = props;
  const [progress, setProgress] = useState(0);
  const [label, setLabel] = useState("");
  const [challenge, setChallenge] = useState("");
  const [nextChallenge, setNextChallenge] = useState("");

  useEffect(() => {
    if (current === 1) {
      setProgress(20);
      setLabel("1 of 5");
      setChallenge('Personality');
      setNextChallenge('Next: Comprehension');
    } else if (current === 2) {
      setProgress(40);
      setLabel("2 of 5");
      setChallenge('Comprehension');
      setNextChallenge('Next: Decoding');
    } else if (current === 3) {
      setProgress(60);
      setLabel("3 of 5");
      setChallenge('Decoding');
      setNextChallenge('Next: Scavenger Hunt');
    } else if (current === 4) {
      setProgress(80);
      setLabel("4 of 5");
      setChallenge('Scavenger Hunt');
      setNextChallenge('Next: Public Key');
    } else if (current === 5) {
      setProgress(100);
      setLabel("5 of 5");
      setChallenge('Public Key');
      setNextChallenge('');
    }
  }, [current]);

  const onLogout = () => {
    localStorage.clear();
    window.location.reload(false);
  };

  return (
    <div className={"page-sidebar"}>
      {!viewMode ? (
        <div
          className="flex-row items-center justify-center"
          style={{ paddingBottom: 20 }}
        >
          <img src={harbingerImage} width="120" alt="profile-img" />
        </div>
      )
        : null}
      <div className="page-sidebar__stepper flex-col">
        <div className="flex-row items-center">
          <div className="bullet done">
            <FontAwesomeIcon icon={faCheck} />
          </div>
          <label className="done">Sign up</label>
        </div>
        <div className="line-connector active"></div>
        <div className="flex-row items-center">
          <div
            className={[
              "bullet",
              complete
                ? "done"
                : current === 1
                  ? "active"
                  : current > 1
                    ? "done"
                    : "",
            ].join(" ")}
          >
            {complete ? (
              <FontAwesomeIcon icon={faCheck} />
            ) : (
              <>
                {current === 1 ? (
                  <FontAwesomeIcon icon={faCircle} />
                ) : current > 1 ? (
                  <FontAwesomeIcon icon={faCheck} />
                ) : (
                  ""
                )}
              </>
            )}
          </div>
          <label className={complete
            ? "done"
            : current === 1
              ? "active"
              : current > 1
                ? "done"
                : ""}>Personality</label>
        </div>
        <div
          className={[
            "line-connector",
            complete ? "active" : current === 2 || current > 2 ? "active" : "",
          ].join(" ")}
        ></div>
        <div className="flex-row items-center">
          <div
            className={[
              "bullet",
              complete
                ? "done"
                : current === 2
                  ? "active"
                  : current > 2
                    ? "done"
                    : "",
            ].join(" ")}
          >
            {complete ? (
              <FontAwesomeIcon icon={faCheck} />
            ) : (
              <span>
                {current === 2 ? (
                  <FontAwesomeIcon icon={faCircle} />
                ) : current > 2 ? (
                  <FontAwesomeIcon icon={faCheck} />
                ) : (
                  ""
                )}
              </span>
            )}
          </div>
          <label>Comprehension</label>
        </div>
        <div
          className={[
            "line-connector",
            complete ? "active" : current === 3 || current > 3 ? "active" : "",
          ].join(" ")}
        ></div>
        <div className="flex-row items-center">
          <div
            className={[
              "bullet",
              complete
                ? "done"
                : current === 3
                  ? "active"
                  : current > 3
                    ? "done"
                    : "",
            ].join(" ")}
          >
            {complete ? (
              <FontAwesomeIcon icon={faCheck} />
            ) : (
              <span>
                {current === 3 ? (
                  <FontAwesomeIcon icon={faCircle} />
                ) : current > 3 ? (
                  <FontAwesomeIcon icon={faCheck} />
                ) : (
                  ""
                )}
              </span>
            )}
          </div>
          <label>Decoding</label>
        </div>
        <div
          className={[
            "line-connector",
            complete ? "active" : current === 4 || current > 4 ? "active" : "",
          ].join(" ")}
        ></div>
        <div className="flex-row items-center">
          <div
            className={[
              "bullet",
              complete
                ? "done"
                : current === 4
                  ? "active"
                  : current > 4
                    ? "done"
                    : "",
            ].join(" ")}
          >
            {complete ? (
              <FontAwesomeIcon icon={faCheck} />
            ) : (
              <span>
                {current === 4 ? (
                  <FontAwesomeIcon icon={faCircle} />
                ) : current > 4 ? (
                  <FontAwesomeIcon icon={faCheck} />
                ) : (
                  ""
                )}
              </span>
            )}
          </div>
          <label>Scavenger Hunt</label>
        </div>
        <div
          className={[
            "line-connector",
            complete ? "active" : current === 5 || current > 5 ? "active" : "",
          ].join(" ")}
        ></div>
        <div className="flex-row items-center">
          <div
            className={[
              "bullet",
              complete
                ? "done"
                : current === 5
                  ? "active"
                  : current > 5
                    ? "done"
                    : "",
            ].join(" ")}
          >
            {complete ? (
              <FontAwesomeIcon icon={faCheck} />
            ) : (
              <span>
                {current === 5 ? (
                  <FontAwesomeIcon icon={faCircle} />
                ) : current > 5 ? (
                  <FontAwesomeIcon icon={faCheck} />
                ) : (
                  ""
                )}
              </span>
            )}
          </div>
          <label>Public Key</label>
        </div>
      </div>
      <div className="page-sidebar__stepper-mobile">
        {!expired && !complete && current !== 6 ? (
          <div className="flex-row justify-between items-center" style={{ width: "100%" }}>
            <ProgressRing
              radius={60}
              stroke={4}
              progress={progress}
              label={label}
            />
            <div className="flex-col items-end" style={{ marginLeft: 20 }}>
              <span className="challenge">
                {challenge}
              </span>
              <span className="next-challenge">
                {nextChallenge}
              </span>
            </div>
          </div>
        ) : (
          ""
        )}
      </div>
      <div style={{
        position: "absolute",
        bottom: 55,
        left: 0,
        right: 0,
        fontSize: 10,
        width: 160,
        margin: "auto",
      }} className="text-center">
        <span
          className="page-sidebar__warning color-red"
        >
          {!complete
            ? days > 0
              ? `You have ${days} days left to submit this application`
              : ""
            : ""}
        </span>
        <span
          className="page-sidebar__warning"
        >
          {viewMode
            ? 'You have already submitted this application and cannot modify it.'
            : ""
          }
        </span>
      </div>
      {!viewMode
        ? (
          <Tooltip title={'Logout'}>
            <div onClick={onLogout} className="c-pointer logout">
              <Row justify="space-between" align="middle" className="width-max">
                <Col>Logout</Col>
                <Col><ExportOutlined style={{ fontSize: 16 }} className="logout-icon" /></Col>
              </Row>
            </div>
          </Tooltip>
        )
        : null
      }
    </div>
  );
};

export default Sidebar;
