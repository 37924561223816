import APIService from "./APIService";

class ScavengerHuntService extends APIService {
  getHash = async () => {
    const result = await this.get("student/apply/scavenger");
    return result ? result.data : null;
  };

  submitAnswer = async (data: any) => {
    const result = await this.post(data, "student/apply/validate/scavenger");
    return result ? result.data : null;
  };
}

export default ScavengerHuntService;
