import APIService from "./APIService";

class DecodingService extends APIService {
  getCode = async () => {
    const result = await this.get("student/apply/ascii");
    return result ? result.data : null;
  };

  submitCode = async (data: any) => {
    const result = await this.post(data, "student/apply/validate/ascii");
    return result ? result.data : null;
  };
}

export default DecodingService;
