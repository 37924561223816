import React, { useEffect, useState } from "react";
import ComprehensionService from "../../../services/ComprehensionService";
import ComprehensionOptions from "./ComprehensionOptions";
import { Timer } from "../../../components/Timer";
import { Beforeunload } from 'react-beforeunload';
import { useHistory } from "react-router-dom";
import { Form, Button } from 'antd';

const ComprehensionView = (props: any) => {
  const [q, setQ]: any = useState([]);
  const [start, setStart] = useState(false);
  const [index, setIndex] = useState(0);
  const [selected, setSelected] = useState("");
  const [timer, setTimer] = useState(true);
  const history = useHistory();

  const { setCurrent, currentQ, viewMode } = props;

  useEffect(() => {
    const initialize = async () => {
      const comprehensionService = new ComprehensionService();
      const result = await comprehensionService.getComprehension();
      setQ(result);

      if (Number(currentQ) > 0) {
        setStart(true);
      }
    };
    initialize();
  }, [currentQ]);

  useEffect(() => {
    if (currentQ !== "") {
      setIndex(Number(currentQ));
    }
  }, [currentQ]);

  const handleSubmit = async () => {
    const data = {
      answer: {
        questionId: q[index].questionId,
        optionId: selected,
      },
    };

    // RESET TIMER
    setTimer(false);
    if (index <= q.length - 1) {
      const comprehensionService = new ComprehensionService();
      if (index < q.length - 1) {
        await comprehensionService.submitComprehension(data);
        // NEXT QUESTION
        setIndex(index + 1);
        setSelected("");

        // RESET TIMER
        setTimer(true);
      } else {
        await comprehensionService.submitComprehensionFinal(data);
        setCurrent(3);
      }
    }

    window.scrollTo({top: 0, behavior: 'smooth'});
  };

  const handleChallengeStart = () => {
    if (q[index].question) {
      setStart(true);
    } else {
      setStart(false);
    }
  }

  const handleBeforeUnload = (event: any) =>  {
    event.preventDefault();
  }

  const backToSettings = async () => {
    history.push("/settings");
  };

  const prevChallenge = async () => {
    setCurrent(1);
  };

  const nextChallenge = async () => {
    setCurrent(3);
  };

  if (!viewMode) {
    return (
      <div className="comprehension-view">
        <div className="flex-col">
          {start ? (
            <div className="wrapper justify-between">
              <span className="color-gray" style={{ marginBottom: 10 }}>
                Question {index + 1} of {q.length}
              </span>
              {timer ? <Timer initialValue={30} timeOut={handleSubmit} /> : ""}
            </div>
          ) : (
            ""
          )}
          <span className="font-18">Comprehension Challenge</span>
          <span className="color-gray" style={{ marginTop: 10 }}>
            {!start
              ? "For this challenge, please watch the below video and answer a series of questions about the speech. The purpose of this assessment is to test your comprehension and listening abilities. We suggest you take notes as you watch."
              : q[index].question
              ? q[index].question
              : ""}
          </span>
        </div>
        <hr></hr>
        <div className="flex-col items-center">
          <div style={{ width: "100%", maxWidth: "550px" }}>
            {!start ? (
              <div
                style={{ padding: "20px 0" }}
                className="flex-col items-center justify-center"
              >
                <div className="pos-relative video-container" >
                  <div className="content" style={{ top: "2px"}}>
                    <iframe
                      allowFullScreen
                      title="Commencement"
                      src={
                        "https://s3.amazonaws.com/school-static-assets/steve-jobs-commencement.mp4"
                      }
                    />
                  </div>
                </div>
                <label
                  className="text-center"
                  style={{ width: "100%", marginBottom: 10 }}
                >
                  When you’re finished the video, click the continue button below.
                </label>
              </div>
            ) : (
              <ComprehensionOptions
                options={q[index].options}
                selectedOpt={setSelected}
              />
            )}
            <div
              style={{ width: "100%" }}
              className="items-center flex-row justify-center"
            >
              {!start ? (
                <Form.Item>
                  <Button
                    type="primary"
                    onClick={handleChallengeStart}
                    data-testid="continue"
                  >
                    Continue
                  </Button>
                </Form.Item> 
              ) : (
                <Form.Item>
                  <Button
                    type="primary"
                    onClick={handleSubmit}
                    data-testid="next"
                  >
                    Next
                  </Button>
                </Form.Item> 
              )}
            </div>
          </div>
        </div>
        <Beforeunload onBeforeunload={handleBeforeUnload} />
      </div>
    );
  } else {
    return (
      <div className="comprehension-view">
        <div className="flex-col">
          <span className="font-18">Comprehension Challenge</span>
          <span className="color-gray" style={{ marginTop: 10 }}>
            For this challenge, please watch the below video and answer a series of questions about the speech. The purpose of this assessment is to test your comprehension and listening abilities. We suggest you take notes as you watch.
          </span>
        </div>
        <hr></hr>
        <div className="flex-col items-center">
          <Form className="form" onFinish={handleSubmit} id="comprehensionChallenge" style={{ marginTop: 10 }}>
            {q.length > 0 && q.map((item: any, index: number) => {
              return (
                <div key={index} style={{ margin: "10px 0", paddingBottom: 10 }}>
                  <span style={{ textAlign: "justify" }}>
                    {(index + 1) + ". " + item.question}
                  </span>
                  <div style={{ 
                    minHeight: 50, 
                    width: 300, 
                    color: "#495057", 
                    padding: 10, 
                    display: "flex", 
                    textAlign: "center", 
                    justifyContent: "center", 
                    alignItems: "center", 
                    background: "#e9ecef", 
                    border: "1px solid #ced4da", 
                    borderRadius: ".25rem", 
                    opacity: 0.75 ,
                    margin: "10px 0"
                  }}>
                    {item.userAnswer && item.options.length > 0 ? (
                      item.options.filter((x: any) => x.optionId === item.userAnswer).map((x: any) => x.option)
                    ) : (
                      'No answer'
                    )}
                  </div>
                </div>
              );
            })}
            <div
              style={{ width: "100%", marginTop: 30, marginBottom: 30 }}
              className="items-center flex-row justify-center"
            >
              <Form.Item>
                <Button
                  type="default"
                  onClick={backToSettings}
                  style={{ marginRight: 10 }}
                  data-testid="back"
                >
                  Go Back
                </Button>
              </Form.Item>
              <Form.Item>
                <Button
                  type="primary"
                  onClick={prevChallenge}
                  style={{ marginRight: 10 }}
                  data-testid="prev"
                >
                  View Previous Challenge
                </Button>
              </Form.Item> 
              <Form.Item>
                <Button
                  type="primary"
                  onClick={nextChallenge}
                  style={{ marginRight: 10 }}
                  data-testid="next"
                >
                  View Next Challenge
                </Button>
              </Form.Item> 
            </div>
          </Form>
        </div>
        {!viewMode ? <Beforeunload onBeforeunload={handleBeforeUnload} /> : null}
      </div>
    );
  }
};

export default ComprehensionView;
