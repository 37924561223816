import React from "react";
import { Button, Result } from 'antd';

const ApplicationExpired = (props: any) => {

  const onSignup = () => {
    localStorage.clear();
    window.location.reload(false);
  };

  return (
    <Result
      status="warning"
      title="Application Expired"
      subTitle="Sorry, there maybe some problems with your application."
      style={{ padding: "100px 32px" }}
      extra={
        <Button type="primary" key="console" onClick={onSignup}>
          Back to Signup
        </Button>
      }
    />
  );
};

export default ApplicationExpired;
