import { useState, useEffect } from "react";
import React from "react";

export const Timer = ({ initialValue, timeOut }: any) => {
  const [counter, setCounter] = useState(initialValue);

  useEffect(() => {
    let timer: any =
      counter > 0 && setInterval(() => setCounter(counter - 1), 1000);
    if (counter === 0) {
      timeOut();
    }
    return () => clearInterval(timer);
  }, [counter, timeOut]);

  return <div className="timer">{counter} seconds left</div>;
};
