import APIService from "./APIService";

class AdmissionService extends APIService {
  getSession = async () => {
    const result = await this.get("student/apply/session");
    return result ? result.data : null;
  };

  saveForLater = async ({ page, number }: any) => {
    const result = await this.put({ page, number }, "student/apply/save");
    return result ? result.data : null;
  };
}

export default AdmissionService;
