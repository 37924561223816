import React, { useEffect, useState } from "react";
import PersonalityService from "../../../services/PersonalityService";
import AdmissionService from "../../../services/AdmissionService";
import { useHistory } from "react-router-dom";
import { Form, Input, Button } from 'antd';
import DialogUtils from '../../../Utility/DialogUtils';

const { TextArea } = Input;

const PersonalityView = (props: any) => {
  const { setCurrent, viewMode } = props;
  const [form] = Form.useForm();
  const [q, setQ] = useState([]);
  const history = useHistory();
  const array_value: any = {};



  useEffect(() => {
    const initialize = async () => {
      const personalityService = new PersonalityService();
      const result = await personalityService.getPersonality();
      setQ(result);

    
      form.setFieldsValue({
        1: result[0].userAnswer,
        2: result[1].userAnswer,
        3: result[2].userAnswer
      });
    };

    initialize();
  }, [form]);

  const handleSubmit = async (event: any) => {
    if (Object.keys(array_value).length === 0) {
      q.forEach((question: any, i: number) => {
        array_value[i] = question.userAnswer;
      });
    } 
    
    if (Object.keys(array_value).length > 0) {
      let data: any = [];
      q.forEach((question: any, i: number) => {
        data.push({
          question: question.statement,
          answer: array_value[i] ? array_value[i] : question.userAnswer,
        });
      });
      const submitData = { personality: data };
      const personalityService = new PersonalityService();
      await personalityService.submitPersonality(submitData);

      const save = {
        page: "comprehension",
        number: 0,
      };
      const admissionService = new AdmissionService();
      await admissionService.saveForLater(save);
      setCurrent(2);
  
      window.scrollTo({top: 0, behavior: 'smooth'});
    }
  };

  const saveLater = async () => {
    const data = {
      page: "personality",
      number: 0,
    };

    const personalityService = new AdmissionService();
    const ifsave = await personalityService.saveForLater(data);

    if(ifsave){
      DialogUtils.success('Successfully Save for later.', () => {});
    }
   

    if (Object.keys(array_value).length === 0) {
      q.forEach((question: any, i: number) => {
        array_value[i] = question.userAnswer;
      });
    } 

    if (Object.keys(array_value).length > 0) {
      let data: any = [];
      q.forEach((question: any, i: number) => {
        data.push({
          question: question.statement,
          answer: array_value[i],
        });
      });
      const submitData = { personality: data };
      const personalityService = new PersonalityService();
      await personalityService.submitPersonality(submitData);
    }

    

  };

  const backToSettings = async () => {
    history.push("/settings");
  };

  const nextChallenge = async () => {
    setCurrent(2);
  };

  const handleValueChange = (event: any, i: number) => {
    array_value[i] = event.target.value;
  };

  return (
    <div className="personality-view">
      <div className="flex-col">
        <span className="font-18">Let's get to know you</span>
        <span className="color-gray" style={{ marginTop: 10 }}>
          Tell us a bit more about yourself by answering these short questions:
        </span>
      </div>
      <hr></hr>
      <Form 
        form={form}
        name="personalityForm"
        layout="vertical"
        onFinish={handleSubmit}
      > 
        {q.map((question: any, index: number) => {
          return (
            <Form.Item
              label={question.statement}
              name={question.questionNumber}
              rules={[{ required: false, message: '' }]}
              key={index}
            >      
              <TextArea 
                rows={4} 
                id={`field-${index}`}
                placeholder="Enter your response here"
                onChange={(e) => handleValueChange(e, index)}
                // defaultValue={question.userAnswer}
                readOnly={viewMode}
                value={array_value[index]}
                style={{ resize: "none" }}
                data-testid={`field-${index}`}
              />     
            </Form.Item>
          )
        })}


        {viewMode ? (
          <div
            style={{ width: "100%", marginTop: 30 }}
            className="items-center flex-row justify-center"
          >
            <Form.Item>
              <Button
                type="default"
                onClick={backToSettings}
                style={{ marginRight: 10 }}
                data-testid="back"
              >
                Go Back
              </Button>
            </Form.Item> 
            <Form.Item>
              <Button
                type="primary"
                onClick={nextChallenge}
                data-testid="next"
              >
                View Next Challenge
              </Button>
            </Form.Item> 
          </div>
        ) : (
          <div
            style={{ width: "100%", marginTop: 30 }}
            className="items-center flex-row justify-center"
          >
            <Form.Item>
              <Button
                type="default"
                onClick={saveLater}
                style={{ marginRight: 10 }}
                data-testid="draft"
              >
                Save for later
              </Button>
            </Form.Item>  
            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                data-testid="submit"
              >
                Continue
              </Button>
            </Form.Item>  
          </div>
        )}
      </Form>
    </div>
  );
};

export default PersonalityView;
