import APIService from "./APIService";

class PersonalityService extends APIService {
  getPersonality = async () => {
    const result = await this.get("student/apply/personality/list");
    return result ? result.data : null;
  };

  submitPersonality = async (data: any) => {
    const result = await this.post(data, "student/apply/submit/personality");
    return result ? result.data : null;
  };
}

export default PersonalityService;
