import React from 'react';
import { Modal } from "antd";
import { ExclamationCircleOutlined } from '@ant-design/icons';

export default class DialogUtils {

    /**
     * 
     * @param content 
     * @param onOk 
     * @param onCancel 
     */
    static confirm(content:any, onOk?:Function, onCancel?:Function) {
        Modal.confirm({
            icon: <ExclamationCircleOutlined />,
            centered: true,
            content: content,
            onOk: () => { if(onOk) onOk() },
            onCancel: () => { if(onCancel) onCancel() }
          });
    }

    
    /**
     * 
     * @param content 
     * @param onOk 
     */
    static error(content:any, onOk?:Function) {
        Modal.error({
            title: 'Error',
            centered: true,
            content: content,
            onOk: () => { if(onOk) onOk() }
          });
    }

    /**
     * 
     * @param content 
     * @param onOk 
     */
    static success(content:any, onOk?:Function ) {
        Modal.success({
            centered: true,
            content: content,           
            onOk: () => { if(onOk) onOk() }
          });
    }
}