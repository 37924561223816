import React, { Component } from "react";
import StudentSidebar from "../pages/student-portal/sidebar/Sidebar";
import { Layout } from "antd";

const { Sider, Content } = Layout;

const StudentLayoutView = ({ children }: any) => {

  return (
    <Layout>
      <Layout>
        <Sider
          theme="light"
          trigger={null}
          collapsible
          style={{ background: "rgb(244, 245, 247)" }}
          width={'16.5rem'}
        >
          <StudentSidebar />
        </Sider>
        <Content className="content-container" style={{ overflow: 'auto' }}>
          {children}
        </Content>
      </Layout>
    </Layout>
  );
}

export default StudentLayoutView;
